import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import styled from "@emotion/styled"
import Typography from "../components/typography"
import colors from "../styles/colors"
import { RequestDemoForm } from "../components/RequestDemoForm"
import CardPartners from "../components/cards/cardPartners"
import { CardSplitImageText } from "../components/cards/CardSplitImageText"
import SupportSpendTime from "../components/illustrations/supportSpendTime"
import { CardMoreInfo } from "../components/cards/requestDemo/cardMoreInfo"
import { MobileMediaQuery } from "../styles/constants"

const FullWidthAlignment = styled.div`
  display: flex;
  width: 100%;

  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: center;
  }
`
const Left = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MobileMediaQuery} {
    text-align: center;
    margin-bottom: 3rem;
  }
`
const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const FullWidthCard = styled(Card)`
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const FullWidth = ({ title, description, rightComponent }) => (
  <FullWidthCard
    variant="lightpurple"
    style={{
      width: "100vw",
      maxWidth: "100vw",
      borderRadius: 0,
      marginLeft: "-4px",
      marginRight: "-4px",
    }}
  >
    <FullWidthAlignment>
      <Left>
        <Typography
          variant="h2"
          style={{ color: colors.primary, marginBottom: "4rem" }}
        >
          {title}
        </Typography>
        <Typography variant="h6">{description}</Typography>
      </Left>
      <Right>{rightComponent}</Right>
    </FullWidthAlignment>
  </FullWidthCard>
)

const RequestDemo = () => {
  const data = useStaticQuery(graphql`
    query {
      drone: file(relativePath: { eq: "tools/drone_square.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1920
            quality: 80
            traceSVG: { background: "#DDDDDE", color: "#232322" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout bg="white" newsletter={null}>
      <SEO title="Request a demo" />
      <FullWidth
        title="The world’s #1 product support platform"
        description={
          <>
            Mavenoid is purpose-built for physical product support. <br />
            <br /> Our AI-powered product assistant solves both repetitive and
            complex issues, while live agent tools empower your team to focus on
            unique problems— so your customers get to the right solutions,
            faster.
            <br />
            <br /> Curious? Let us show you.
          </>
        }
        rightComponent={
          <RequestDemoForm
            from="demo-request-LP"
            formTitle="Schedule a demo to learn more"
          />
        }
      />
      <CardPartners variant="white" />
      <CardSplitImageText
        title={
          <>
            Self Service—
            <br />
            Support automation that actually works
          </>
        }
        description={
          <>
            Mavenoid Self Service lets you automate the single biggest time
            thief for support teams: repetitive troubleshooting.
            <br />
            <br />
            With powerful features like natural language processing, flexible
            support flows and a teachable AI, we can solve the real-world
            problems that chatbots & help centers can’t.
          </>
        }
        variant="lightpurple"
        image={<SupportSpendTime />}
      />
      <CardSplitImageText
        isReverse
        title="Live Support—Power to the agent"
        description={
          <>
            Mavenoid Live Support gives your agents the tools they need to solve
            your customer’s most complex problems.
            <br />
            <br />
            One-touch video calls, live chat and AI-powered recommendations
            empower your teams to provide the best customer support ever.
          </>
        }
        variant="primary"
        fluid={data.drone.childImageSharp.fluid}
      />
      <CardMoreInfo />
    </Layout>
  )
}

export default RequestDemo
